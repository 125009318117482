import { Controller } from "@hotwired/stimulus"
import BrowserPrint from "zebra-print"

export default class extends Controller {
  static values = {
    content: String,
    url: String,
    use: String, // small: ZD411, big: ZD421
    amount: Boolean
  }

  static targets = [ 'device', 'content', 'go', 'param' ] 

  connect() {
    this.maxWidth = {
      'ZD411': 420,
      'ZD421': 780
    }
    this.originalContent = this.contentValue;
    this.printers = false;
    this.used = false;
    this.no_browserprint = false;
    this.amount = 1;
    this.errors = [
      'Labels kunnen niet worden geprint. Start de applicatie \'Zebra Browser Print\'.',
      'Nog geen printers gevonden. Probeer nogmaals. Het kan even duren voordat de printers zijn gevonden.',
      `Opgegeven printer (${this.useValue}) niet gevonden.`,
      'Geen text opgegeven voor het label.',
      'Geen geldig aantal opgegeven.'
    ]

    this.goTarget.disabled = true;
    this.goTarget.title = this.errors[0];

    BrowserPrint.getApplicationConfiguration(() => {}, (error) => {
      this.no_browserprint = true
      return false;
    });

    BrowserPrint.getLocalDevices( (devices) => {
      this.printers = devices.printer;
      this.goTarget.disabled = false;
      this.goTarget.title = '';
    });
  }

  now(e) {
    e.preventDefault(); 

    if (this.amountValue) {
      this.amount = prompt("Hoeveel labels moeten er geprint worden?", 1)
    }

    // when amount prompt is cancelled
    if (this.amount == null || this.amount == "") return false

    // not a valid number
    if (isNaN(parseInt(this.amount))) return alert(this.errors[4])

    // show message when browser print app is not available
    if (this.no_browserprint) return alert(this.errors[0])

    // when no printers found
    if (!this.printers) return alert(this.errors[1])
    
    // set used printer based on the use value
    this.used = this.printers.find((printer) => printer.name.includes(this.useValue))

    // check if given printer exists
    if (!this.used) return alert(this.errors[2])

    // check if label has value
    if (!this.hasContentValue && !this.hasUrlValue) return alert(this.errors[3])
    if (this.contentValue === '' && this.urlValue === '') return alert(this.errors[3])

    for (let i = 0; i < this.amount; i++ ) {
      // print content
      if (this.hasContentValue) {
        this.setContent();
        this.used.send(this.contentValue, undefined, (error) =>  { return alert(error) });
      }

      // print url
      if (this.hasUrlValue) {
        let url = this.urlValue;
        if (this.hasParamTarget) url += `?${this.paramTarget.name}=${this.paramTarget.value}`;  
        this.used.sendFile(url, undefined, (error) => { return alert(error) })
      }
    }
  }

  setContent() {
    if (this.hasContentTarget) {
      this.contentValue = this.originalContent.replace('%YIELD%', this.contentTarget.value.replaceAll("\n", "\\&"));
      this.contentValue = this.contentValue.replace('%MAXWIDTH%', this.maxWidth[this.useValue]);
    }
  }

  setDevice() {
    if (this.hasDeviceTarget) this.useValue = this.deviceTarget.value;
    this.setContent();
  }

  setUrl(e) {
    this.urlValue = e.currentTarget.value;
  }
}